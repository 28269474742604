export default [
  {
    title: "formulas",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/Formulas.svg"),

    children: [
      {
        src: require("@/assets/images/icons/SingleBlend.svg"),
        title: "singleFormula",
        route: {
          name: "plantFormulas",
          params: { tur: "singleFormula" },
        },
        resource: "Single",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/MultiBlend.svg"),
        title: "multiFormula",
        route: {
          name: "multiFormulas",
        },
        resource: "Multi",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/productionformulas.svg"),
        title: "productionFormulas",
        route: { name: "productionFormulas" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/productionArchive.svg"),
        title: "productionFormulasArchive",
        route: { name: "productionFormulasArchive" },
        resource: "Auth",
        action: "read",
        exact: true,
      },

      {
        src: require("@/assets/images/icons/templates.svg"),
        title: "formulaTemplates",
        route: {
          name: "formulaTemplates",
        },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/compare.svg"),
        title: "compareFormulas",
        route: {
          name: "compareFormulas",
        },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      // {
      //   icon: "RepeatIcon",
      //   title: "changeFormulaErpCode",
      //   route: {
      //     name: "changeFormulaErpCode",
      //   },
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
    ],
  },
];
