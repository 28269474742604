export default [
  {
    title: "comppanySettings",
    resource: "CompanyAdmin",
    action: "read",
    icon: "SettingsIcon",

    children: [
      {
        icon: require("@/assets/images/icons/settings.svg"),

        title: "generalSettings",
        route: "general-settings",
        resource: "CompanyAdmin",
        action: "read",
      },
      {
        src: require("@/assets/images/icons/users.svg"),
        title: "users",
        route: "users",
        resource: "CompanyAdmin",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/modules.svg"),
        title: "packages",
        route: "packages",
        resource: "CompanyAdmin",
        action: "read",
        exact: true,
      },

      // {
      //   src: require("@/assets/images/icons/ingredients.svg"),
      //   title: "companyProducts",
      //   route: "companyProduct",
      //   resource: "CompanyAdmin",
      //   action: "read",
      //   exact: true,
      // },
    ],
  },
];
