export default [
  {
    title: "ingredients",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/ingredients.svg"),

    children: [
      {
        src: require("@/assets/images/icons/ingredients.svg"),
        title: "ingredients",
        route: { name: "ingredients" },
        resource: "Auth",
        action: "read",
        exact: true,
      },

      {
        src: require("@/assets/images/icons/prices.svg"),
        title: "ingredientPrices",
        route: { name: "ingredientsPrices" },
        resource: "Auth",
        action: "read",
        exact: true,
      },

      {
        src: require("@/assets/images/icons/changecode.svg"),
        title: "ingredientChangeCode",
        route: { name: "ingredientCodeChange" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/taginfo.svg"),
        title: "ingredientTags",
        route: { name: "ingredientTags" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      // {
      //   src: require("@/assets/images/icons/copy.svg"),
      //   title: "copyIngredientNutrientValues",
      //   route: { name: "copyIngredientNutrientValues" },
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
    ],
  },
];
