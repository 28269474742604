export default [
  {
    title: "nutrients",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/products.svg"),

    children: [
      {
        src: require("@/assets/images/icons/products.svg"),
        title: "nutrients",
        route: { name: "nutrients" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/nutrientequations.svg"),
        title: "nutrientEquations",
        route: { name: "nutrientEquations" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        icon: "LayersIcon",
        title: "additionalValues",
        route: { name: "additionalValues" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/prices.svg"),
        title: "nutrientMatrix",
        route: { name: "nutrientMatrix" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/taginfo.svg"),
        title: "nutrientTags",
        route: { name: "nutrientTags" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
    ],
  },
];
