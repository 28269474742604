export default [
  {
    title: "analyze",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/analyze.svg"),
    children: [
      {
        src: require("@/assets/images/icons/formulaVerification.svg"),
        title: "formulaVerification",
        route: { name: "formulaVerification" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/periodicIngredientCalculation.svg"),
        title: "periodicIngredientCalculation",
        route: { name: "periodicIngredientCalculation" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
    ],
  },
];
