import analyze from "./analyze";
import companySettings from "./company";
import formulas from "./formulas";
import rations from "./formulas";
import generalActions from "./generalActions";
import ingredient from "./ingredient";
import nutrients from "./nutrients";

export default [
  // {
  //   title: "Admin",
  //   resource: "AdminAuth",
  //   action: "read",
  //   icon: "PieChartIcon",

  //   children: [
  //     {
  //       title: "Dashboard",
  //       route: "Admin",
  //       resource: "Auth",
  //       action: "read",
  //       exact: true,
  //     },
  //     {
  //       title: "Companies",
  //       route: "Companies",
  //       resource: "Auth",
  //       action: "read",
  //       exact: true,
  //     },
  //   ],
  // },
  {
    icon: "AirplayIcon",
    title: "homePage",
    route: "dashboard",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  ...formulas,
  ...ingredient,
  ...nutrients,

  ...analyze,
  {
    src: require("@/assets/images/icons/plants.svg"),
    title: "plants",
    route: "plants",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  // {
  //   src: require("@/assets/images/icons/visits.svg"),
  //   title: "plantVisits",
  //   route: { name: "plantVisits" },
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  // {
  //   src: require("@/assets/images/icons/orders.svg"),
  //   title: "plantOrders",
  //   route: { name: "plantOrders" },
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },

  ...companySettings,
  // {
  //   src: require("@/assets/images/icons/home.svg"),
  //   title: "machines",
  //   route: { name: "machines" },
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  {
    src: require("@/assets/images/icons/analyze.svg"),
    title: "asIf",
    route: { name: "as-if" },
    resource: "Admin",
    action: "read",
    exact: true,
  },
];
